import { Search } from 'auto-libs'
import { Layout, Loading, Toast } from 'auto-ui'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ReactSwipe from 'react-swipe'
import { useSetState } from 'react-use'
import http from '../../utils/http'
import { sleepSize } from '../../utils/index'
import { getStorageUUID } from '../../utils/storage'
import './style.scss'

const ageStage = [
  {
    age: 10,
    key: 0,
  },
  {
    age: 20,
    key: 1,
  },
  {
    age: 30,
    key: 2,
  },
  {
    age: 40,
    key: 3,
  },
  {
    age: 50,
    key: 4,
  },
  {
    age: 60,
    key: 5,
  },
  {
    age: 70,
    key: 6,
  },
  {
    age: 80,
    key: 7,
  },
]

export default function PageIndex() {
  const navigate = useNavigate()
  const channel = Search.get('channel') || ''

  let reactSwipeEl: any = null
  let uploadFile: any = useRef(null)

  const [state, setState] = useSetState({
    age: 10,
    index: 0,
    orderId: '',
  })

  const onTransitionEnd = (index: number) => {
    const age = ageStage[index].age
    setState({
      index,
      age,
    })
  }

  const onAgePickerChange = (e: any) => {
    const value = parseInt(e.target.value, 10)
    const index = ageStage.findIndex((item) => item.age === value) || 0
    reactSwipeEl.slide(index)
  }

  const onSwiperLeft = () => {
    reactSwipeEl.prev()
  }

  const onSwiperRight = () => {
    reactSwipeEl.next()
  }

  const onFileChange = async (e: any) => {
    try {
      Loading()

      const file = e.target.files[0]

      const awaitFile = await sleepSize(file)

      if (!awaitFile) {
        Toast('图片上传失败，请重新上传或尝试拍照上传')
        return
      }

      if (file.type.indexOf('image') < 0) {
        Toast('请上传正确的图片类型')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const res: any = await http.request({
        url: '/file/upload',
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (res.code !== '00000000') {
        Toast('图片上传失败，请重试')
        return
      }

      const result: any = await http.request({
        url: '/order/create',
        method: 'POST',
        data: {
          productType: '4', // 1:姓名匹对，2：智商测试 ,3: MBTI,4:时光穿梭机
          channel,
          filePath: res.body.filePath,
          toAge: state.age,
        },
      })

      if (result.code !== '00000000') {
        Toast('请求失败，请再试')
        return
      }

      setState({
        orderId: result.body.orderId,
      })

      uploadFile.current.value = null

      navigate(`/pay?url=${res.body.viewUrl}&orderNo=${result.body.orderId}&age=${state.age}`, {
        replace: false,
      })
    } catch (e: any) {
      Toast(e.msg || '系统错误')
    } finally {
      Loading.hide()
    }
  }

  const statistics = async () => {
    const params: any = { userId: getStorageUUID() }

    if (channel) {
      params.channel = channel
    }

    await http.request({
      url: '/statistics/submit',
      method: 'POST',
      data: params,
    })
  }

  useEffect(() => {
    statistics()
  }, [])

  return (
    <Layout className="page-index" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="slogan" />
        <div className="gallery">
          <div className="arrow left" onClick={onSwiperLeft} />
          <div className="arrow right" onClick={onSwiperRight} />

          <div className="gallery-wrapper">
            <ReactSwipe
              className="gallery-list"
              swipeOptions={{ startSlide: state.index, transitionEnd: onTransitionEnd }}
              childCount={ageStage.length}
              ref={(el: any) => {
                reactSwipeEl = el
              }}
            >
              {ageStage.map((item) => {
                const swiperItemClassName = cn(`gallery-item g${item.key}`)
                return (
                  <div key={item.key} className={swiperItemClassName}>
                    <div className="age">{item.age}岁</div>
                  </div>
                )
              })}
            </ReactSwipe>
          </div>
        </div>
        <div className="select-age">
          <select className="select-el" value={state.age} onChange={onAgePickerChange}>
            {ageStage.map((item) => (
              <option value={item.age} key={item.key}>
                我想穿越到{item.age}岁
              </option>
            ))}
          </select>
          <div className="picker" />
        </div>
        <div className="desc">
          你有没有幻想过自己可以像哆啦A梦一样
          <br />
          任意穿梭时空呢？快来试试吧
        </div>
        <div className="tip">请选择正脸自拍，面部无遮挡哦</div>
        <div className="btns">
          <div className="btn" />
          <input
            type="file"
            ref={uploadFile}
            accept="image/*"
            className="file"
            onChange={onFileChange}
          />
        </div>
      </Layout.Body>
    </Layout>
  )
}

import axios from 'axios'

class HttpError extends Error {
  constructor(message: string, data: any) {
    super(message)

    this.message = message
    if (data) {
      this.data = data
    }
  }

  data: any
}

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : '/api',
})

instance.interceptors.request.use(
  (config: any) => {
    // config.headers['Content-Type']? config.headers['Content-Type']  = 'application/json;'

    if (config.method.toLocaleLowerCase() === 'get') {
      config.params = config.params || {}
    }

    return { ...config }
  },
  (error) => {
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    const data = response.data || {}
    if (data.code === '00000000') {
      return data
    }

    // reject错误处理
    return Promise.reject(new HttpError(data.desc, data))
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default instance

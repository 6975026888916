import { Search } from 'auto-libs'
import { Alert, Button, Layout, Loading, Toast } from 'auto-ui'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetState } from 'react-use'
import ClassifyHeader from '../../components/classify-header'
import Scan from '../../components/scan'
import AdCarton from '../../imgs/banner-carton.jpg'
import http from '../../utils/http'
import { sleepSize } from '../../utils/index'
import { aliPay, wxPay } from '../../utils/pay'
import './style.scss'

const Result = () => {
  const orderId = Search.get('orderNo') || ''
  const navigate = useNavigate()

  let uploadFile: any = useRef(null)
  let payWay = 'WXPAY'

  const [state, setState] = useSetState<any>({
    photoAnimation: false,
    alipayForm: null,
    filePath: '',
    oldUrl: '',
    newUrl: '',
    age: 0,
    reupload: false,
    loading: false,
  })

  const onWechatPay = async () => {
    try {
      Loading()
      const result = await wxPay(orderId)
      window.location.href = result
    } catch (error: any) {
      Toast(error.message || '系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const onAlipay = async () => {
    try {
      Loading()
      const result = await aliPay(orderId)

      setState({ alipayForm: result })

      setTimeout(() => {
        document.forms[0].submit()
      }, 100)
    } catch (error) {
      Toast('系统异常，请重试')
    } finally {
      Loading.hide()
    }
  }

  const fetchResult = async (filePath = '') => {
    try {
      if (!orderId) {
        Toast('地址不正确，请回首页重新测试')
        return
      }

      Loading()

      setState({ loading: true })

      const payResult: any = await http.request({
        url: '/order/queryStatus',
        method: 'POST',
        data: {
          orderId,
        },
      })

      let { status, payMethod } = payResult.body
      if (payResult.code !== '00000000' || status !== 2) {
        payWay = payMethod
        checkPayStatus()
        return
      }

      const result: any = await http.request({
        url: '/result/queryPictureResult',
        method: 'POST',
        data: {
          orderId,
          filePath,
        },
      })

      const { body } = result
      const { newPictureViewUrl, oriPictureViewUrl, toAge } = body
      if (!newPictureViewUrl) {
        Alert(`${result.message} 请重新上传图片`)

        setState({
          oldUrl: oriPictureViewUrl,
          age: toAge,
          reupload: false,
        })
        return
      }

      setState({
        oldUrl: oriPictureViewUrl,
        newUrl: newPictureViewUrl,
        age: toAge,
        passThrouging: true,
        reupload: false,
      })

      setTimeout(() => {
        setState({ photoAnimation: true, passThrouging: false })
      }, 3000)
    } catch (e: any) {
      if (e.data && e.data.code === '10300005') {
        Alert(`${e.message} 请重新上传图片`)
        setState({
          reupload: true,
          oldUrl: e.data.body.oriPictureViewUrl,
          age: e.data.body.toAge,
        })
      } else {
        Toast(e.message || '系统异常')
      }
    } finally {
      Loading.hide()
      setState({ loading: false })
    }
  }

  const toPay = async () => {
    try {
      Loading()
      if (payWay === 'WXPAY') {
        await onWechatPay()
      } else {
        await onAlipay()
      }
    } catch (error: any) {
      Toast(error.message || '系统异常，请重新再试')
    } finally {
      Loading.hide()
    }
  }

  const checkPayStatus = () => {
    Alert({
      title: '请确认支付是否完成',
      btnDirection: 'column',
      btns: [
        {
          name: '已完成支付',
          type: 'danger',
          hollow: true,
          onClick: () => {
            fetchResult()
          },
        },
        {
          name: '支付遇到问题，重新支付',
          type: 'danger',
          onClick: () => {
            toPay()
          },
        },
      ],
    })
  }

  const onReTry = () => {
    navigate('/', { replace: true })
  }

  const onFileChange = async (e: any) => {
    try {
      Loading()

      const file = e.target.files[0]

      const awaitFile = await sleepSize(file)

      if (!awaitFile) {
        Toast('图片上传失败，请重新上传或尝试拍照上传')
        return
      }

      if (file.type.indexOf('image') < 0) {
        Toast('请上传正确的图片类型')
        return
      }

      const formData = new FormData()
      formData.append('file', file)

      const res: any = await http.request({
        url: '/file/upload',
        data: formData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (res.code !== '00000000') {
        Toast('图片上传失败，请重试')
        return
      }

      await fetchResult(res.body.filePath)
    } catch (e: any) {
      Toast(e.msg || '系统错误')
    } finally {
      uploadFile.current.value = null

      Loading.hide()
    }
  }

  useEffect(() => {
    fetchResult()
  }, [])

  return (
    <Layout className="page-result" useBottomSafeArea={false}>
      <Layout.Body>
        <div className="slogan" />
        <div className="photo-ad">
          <div className="photo-pass-wrapper">
            <div
              className={`item local ${state.photoAnimation ? 'animation' : ''}`}
              style={{ background: `url(${state.oldUrl}) no-repeat 50% / cover` }}
            />
            <div
              className={`item passthrough ${state.photoAnimation ? 'animation' : ''}`}
              style={{ background: `url(${state.newUrl}) no-repeat 50% / cover` }}
            >
              <img
                src={state.newUrl}
                style={{
                  opacity: 0,
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  left: 0,
                  top: 0,
                  position: 'absolute',
                  zIndex: 999,
                  // @ts-ignore
                  '-webkit-touch-callout': 'default',
                }}
              />
            </div>
            {!state.loading && <div className="age">{state.age}岁</div>}
            {state.passThrouging && <Scan />}
          </div>

          <div className="passthrough-handle">
            <div className="btns">
              {state.reupload && (
                <Button type="secondary" className="btn">
                  重新上传图片
                  <input
                    type="file"
                    ref={uploadFile}
                    accept="image/*"
                    className="file"
                    onChange={onFileChange}
                  />
                </Button>
              )}
              {!state.reupload && !state.passThrouging && (
                <Button type="secondary" onClick={onReTry} className="btn">
                  再测一次
                </Button>
              )}
            </div>

            {!state.reupload && !state.passThrouging && (
              <div className="tip">温馨提示：长按图片可以保存到相册哦</div>
            )}
          </div>

          <div className="ads">
            <ClassifyHeader title="更多好玩" />

            <a className="ad" href="https://dmtx.ijianshui.com?channel=zzz3$51">
              <img src={AdCarton} />
            </a>
          </div>

          <div
            id="alipay_form"
            style={{ display: 'none' }}
            dangerouslySetInnerHTML={{ __html: state.alipayForm }}
          />
        </div>
      </Layout.Body>
    </Layout>
  )
}

export default Result

export const sleepSize = (file: any) => {
  let count = 20
  return new Promise((resolve) => {
    if (file.size) {
      resolve(true)
    }
    const handeler = setInterval(() => {
      if (count < 0) {
        window.clearInterval(handeler)
        resolve(false)
      }
      if (file.size) {
        window.clearInterval(handeler)
        resolve(true)
      }
      count--
    }, 500)
  })
}
